@charset "utf-8";

// Example Defaults
// $base-font-family: Helvetica, Arial, sans-serif;
// $base-font-size:   16px;
// $small-font-size:  $base-font-size * 0.875;
// $base-line-height: 1.5;

// $spacing-unit:     30px;

// $text-color:       #111;
// $background-color: #fdfdfd;
// $brand-color:      #2a7ae2;

// $grey-color:       #828282;
// $grey-color-light: lighten($grey-color, 40%);
// $grey-color-dark:  darken($grey-color, 25%);

// // Width of the content area
// $content-width:    800px;

// $on-palm:          600px;
// $on-laptop:        800px;

// Using media queries with like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }

body {
        background-color: black;
}

.spotify-header {
        margin: auto;
        padding-top: 25px;
        width: 80%;
        height: 150px;

}

.button {
        border: none;
        color: rgba(255, 255, 255, 0.479);
        line-height: 65px;
        margin: 5px;
        width: 65px;
        height: 65px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        border-radius: 40px;
        font-weight: 500;
}

.button.spotify {
        background-color:#1DB954;
        color: white;
        font-size: 40px;
}

.button.soundcloud {
        background-color: #ff7700;
        color: white;
        font-size: 40px;
}

.button.bandcamp{
        background-color: #629aa9;
        font-size: 40px;
}

.bandcamplogo{
        color: white;
        font-size: 40px;
        content: "\f04d";  //fa-square content
        display: inline-block; //inline elements can't be transformed
        transform: skew(-25deg);
        -webkit-transform: skew(-25deg);
        -moz-transform: skew(-25deg);
        -ms-transform: skew(-25deg);
        -o-transform: skew(-25deg);
        
}

.button.twitter {
        background-color: #00acee;
        color: white;
        font-size: 40px;
}

.button.facebook {
        background-color: #3b5998;
        color: white;
        font-size: 40px;
}

.button.apple {
        background-color: #ffffff;
        color: #B166CC;
        font-size: 40px;
}

.button.instagram {
        background-color: #3f729b;
        color: white;
        font-size: 40px;
}

// variables.less
$theme-primary: #02bd12;
$theme-dark: #222;
// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "mixins",
        "base",
        "spotify-embed"
;
