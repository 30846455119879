.embed-spotify {
  position: relative;
  padding-bottom: 40px;
  height: 340px;
  // overflow: hidden;
  max-width: 100%;
}

.embed-spotify iframe, .embed-spotify object, .embed-spotify embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 340px;
  padding-bottom: 40px;

}